.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-between-end {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.custom-toolbar {
  /* height: 120px; */
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.shake {
  animation: shake 3s infinite;
}

@keyframes shake {
  0% {
    transform: rotate(0deg);
  }
  3% {
    transform: translateY(-4px) rotate(-15deg);
  }
  6% {
    transform: translateY(-4px) rotate(15deg);
  }
  9% {
    transform: translateY(-4px) rotate(-15deg);
  }
  12% {
    transform: translateY(-4px) rotate(15deg);
  }
  15% {
    transform: translateY(-4px) rotate(-15deg);
  }
  18% {
    transform: translateY(-4px) rotate(15deg);
  }
  21% {
    transform: translateY(-4px) rotate(-15deg);
  }
  24% {
    transform: translateY(-4px) rotate(15deg);
  }
  27% {
    transform: translateY(-4px) rotate(-15deg);
  }
  30% {
    transform: translateY(-4px) rotate(5deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
}